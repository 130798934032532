import "@css/studio053/styles.pcss";
import "@css/studio053/style.pcss";


/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

/* JS */
//import "@js/parts/lazyloading";
import '@js/parts/timer';
import '@js/parts/counter';

document.addEventListener('DOMContentLoaded', function () {
  const links = document.querySelectorAll('.nav-link-flying-anchor');
  links.forEach(function (link) {
    link.addEventListener('click', function (event) {
      event.preventDefault();
      const target = this.getAttribute('href');
      if (!target) return;
      const link = target.replace('#', '')
      window.scrollToElement(link);
      const navbarToggler = document.querySelector('.navbar-toggler');
      const navbarCollapse = document.querySelector('.navbar-collapse');
      navbarToggler.classList.add('collapsed');
      navbarCollapse.classList.remove('show');
    });
  });
});

window.scrollToElement = function (target) {
  const element = document.getElementById(target);
  if (!element) return;

  const offset = 30;
  const offsetPosition = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
}


/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
